/*================================================================================
				TITLE:	IE Tweak Style Sheet
  TARGET SPEC:	CSS 2
		 MODIFIED:	2012.11.14
 	  AUTHOR(S):	Susan Ferguson-Wheeler / Able Engine - www.ableengine.com
==================================================================================*/

.cleared { zoom: 1; }
#build-form #teamstore-url { width: 135px; margin-left: 7px; float: none; display: inline; }
.page-dashboard-secure-popup .current-access-code {
	width: 168px;
	height: 28px;
	border: 1px solid #afafaf;
	font: bold 16px/1 'Figtree', Verdana, Helvetica, sans-serif;
	color: #ff8600;
	background-color: #fff;
	text-align: center;
	display: block;
	vertical-align: middle;
	padding: 10px 0px 0px;
}

#build-form #teamstore-protection-checkbox input { top: 0px; }

#build-form #sport-checkboxes input { width: 15px; top: 1px; }

#build-form #terms input { top: 0px; }

#form-search-teamstores td:first-child input { padding-top: 11px; padding-bottom: 0px; height: 33px; }

/* Added 10/26/2012 */
#build-form td#site-type input { top: 1px; }

.page-select-product #select-product-container, .page-select-category #select-product-container { padding-bottom: 20px; }
